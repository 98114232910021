import config from '../siteConfig';

// TODO: Cookies: https://github.com/Mastermindzh/react-cookie-consent
// https://dev.to/ramonak/react-enable-google-analytics-after-a-user-grants-consent-5bg3

export const pageview = url => {
  // check if on dev environment
  const isDev = window && window.location.host.includes('localhost');
  // create custom pageview event
  const pageEvent = {
    event: 'pageview',
    page: `${url}`
  };
  // if GTM script loaded on window
  if (window && window.dataLayer) {
    if (!isDev) {
      // if not on dev, send GTM page events
      window.dataLayer.push(pageEvent);
    }
  }
  return pageEvent;
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const gtmEvent = eventData => {
  if (typeof eventData !== 'object') {
    console.log('Err on gtm event trigger');
    return null;
  }
  // check if on dev environment
  const isDev = window && window.location.host.includes('localhost');
  // https://developers.google.com/tag-manager/devguide#variable-names-should-be-enclosed-in-quotes
  const keys = Object.keys(eventData);
  const parsedEvent = keys.reduce(
    (acc, curr) => ({ ...acc, [`${curr}`]: `${eventData[curr]}` }),
    {}
  );
  console.log({ parsedEvent });
  // if GTM script loaded on window
  if (window && window.dataLayer) {
    if (!isDev) {
      // if not on dev, send GTM page events
      window.dataLayer.push(parsedEvent);
    }
  }
};

export const setGAUserId = userID => {
  // check if on dev environment
  const isDev = window && window.location.host.includes('localhost');
  const userIdEvent = {
    user_id: userID
  };
  const userIDEventClone = {
    userID,
    userId: userID
  };
  // console.log({ userIdEvent });
  // if GTM script loaded on window
  if (window && window.dataLayer) {
    if (!isDev) {
      // if not on dev, send GTM event
      window.dataLayer.push(userIdEvent);
      window.dataLayer.push(userIDEventClone);
    }
  }
  return userIdEvent;
};

// https://developers.google.com/analytics/devguides/collection/ga4/display-features
export const disableAdsTracking = () => {
  // check if on dev environment
  const isDev = window && window.location.host.includes('localhost');
  const adsEvent = {
    allow_google_signals: false,
    allow_ad_personalization_signals: false
  };
  // if GTM script loaded on window
  if (window && window.dataLayer) {
    if (!isDev) {
      // if not on dev, send GTM event
      window.dataLayer.push(adsEvent);
    }
  }
  return adsEvent;
};
export const reEnableAdsTracking = () => {
  // check if on dev environment
  const isDev = window && window.location.host.includes('localhost');
  const adsEvent = {
    allow_google_signals: true,
    allow_ad_personalization_signals: true
  };
  // if GTM script loaded on window
  if (window && window.dataLayer) {
    if (!isDev) {
      // if not on dev, send GTM event
      window.dataLayer.push(adsEvent);
    }
  }
  return adsEvent;
};

export const disableGA = () => {
  // https://developers.google.com/analytics/devguides/collection/ga4/disable-analytics
  if (window) {
    localStorage.setItem(`ga-disable-${config.googleAnalyticsID}`, JSON.stringify(true));
    window[`ga-disable-${config.googleAnalyticsID}`] = true;
  }
};

export const enableGA = () => {
  // https://developers.google.com/analytics/devguides/collection/ga4/disable-analytics
  if (window) {
    localStorage.removeItem(`ga-disable-${config.googleAnalyticsID}`);
    window[`ga-disable-${config.googleAnalyticsID}`] = false;
  }
};
// // https://developers.google.com/analytics/devguides/collection/gtagjs/pages
// export const pageview = url => {
//   // for GA simple
//   // window.gtag('config', config.googleTagManagerID, {
//   //   page_path: url
//   // });
// };

// // https://developers.google.com/analytics/devguides/collection/gtagjs/events
// export const event = ({ action, category, label, value }) => {
//   window.gtag('event', action, {
//     event_category: category,
//     event_label: label,
//     value
//   });
// };
