export default {
  en: {
    product: 'Product',
    resources: 'Resources',
    company: 'Company',
    logoLead: 'The easiest way to create polls and to collect responses on the internet!',
    home: 'Home',
    features: 'Features',
    premium: 'Premium',
    dashboard: 'Dashboard',
    templates: 'Templates',
    guides: 'Guides',
    sales: 'Sales',
    contact: 'Contact',
    copyright: 'pollme.app is a solution from CHAMLABS, LLC. All rights reserved.',
    terms: 'Terms of Use',
    privacy: 'Privacy Policy'
  },
  es: {
    product: 'Producto',
    resources: 'Recursos',
    company: 'Compañía',
    logoLead: '¡La forma más sencilla de crear encuestas y recopilar respuestas en Internet!',
    home: 'Inicio',
    features: 'Características',
    premium: 'Premium',
    dashboard: 'Dashboard',
    templates: 'Templates',
    guides: 'Guías',
    sales: 'Ventas',
    contact: 'Contacto',
    copyright: 'pollme.app is a solution from CHAMLABS, LLC. All rights reserved.',
    terms: 'Términos de Uso',
    privacy: 'Políticas de Privacidad'
  }
};
