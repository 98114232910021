/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://33hfimrzircotem4oyghdkvxcm.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-dunl3jvszvgmhpqvyrlanmlhby",
    "aws_cognito_identity_pool_id": "us-west-2:69c68191-5cdb-4a3e-916e-424272481d5d",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_SENubcj0o",
    "aws_user_pools_web_client_id": "2ddd9qk51524s7d1nk1juseb5k",
    "oauth": {
        "domain": "pollmeapp6c81d937-6c81d937-dev.auth.us-west-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/,https://pollme.app/",
        "redirectSignOut": "http://localhost:3000/,https://pollme.app/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS"
};


export default awsmobile;
