/* eslint-disable camelcase */
import styled, { css } from 'styled-components';
import dynamic from 'next/dynamic';
import Modal, { ModalProvider } from 'styled-react-modal';
import { Icon } from 'react-icons-kit';
import { ic_close } from 'react-icons-kit/md/ic_close';
import { ic_chevron_right } from 'react-icons-kit/md/ic_chevron_right';
// import Tippy from '@/components/Tooltip';
const Tippy = dynamic(() => import('@/components/Tooltip'), { ssr: false });

// https://github.com/AlexanderRichey/styled-react-modal#modal

const StyledModal = Modal.styled`
`;

const ModalContainer = styled.div`
  width: ${({ modalWidth }) => modalWidth || '70vw'};
  height: ${({ modalHeight }) => modalHeight || '80vh'};
  padding: 1.5rem 2.5rem;
  background-color: var(--white);
  border-radius: var(--border-radius);
  position: relative;
  display: grid;
  grid-template-rows: 3.5rem auto 3.5rem;
  .close-btn {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.2rem 0.25rem 0.15rem 0.2rem;
      margin: 0;
      border: 0;
      outline: 0;
      i {
        display: flex !important;
        padding: 0;
        margin: 0;
        align-items: center;
        justify-content: center;
      }
    }
  }
  @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.md}) {
    width: 80vw;
    height: 80vh;
  }
  @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.sm}) {
    width: 90vw;
    height: 80vh;
    padding: 1.5rem 1.25rem;
  }
`;

const ModalHeader = styled.div`
  padding-bottom: 0.5rem;
  h1 {
    margin: 0;
  }
`;

const ModalBody = styled.div`
  ${'' /* padding: 1rem 0; */}
  overflow: auto;
  .group-title {
    margin-top: 0;
  }
  .builder-templates {
    padding: 1rem 0;
  }
`;

const ModalFooter = styled.div`
  display: grid;
  grid-template-columns: ${({ footerOptions }) => (footerOptions ? '150px' : '150px 120px')};
  ${({ gtc }) =>
    gtc &&
    css`
      grid-template-columns: ${gtc};
    `};
  grid-gap: 0.25rem;
  place-content: end;
  align-items: center;
  button {
    width: 100%;
    padding: 0.5rem 1.25rem;
    height: 100%;
    outline: none;
  }
  .cancel-btn {
    background-color: var(--white);
    border: 1px solid transparent;
    &:hover {
      background-color: var(--white-hover);
      border-color: var(--muted-strong);
    }
  }
  .continue-btn {
    height: 100%;
    button {
      padding: 0.5rem 1.25rem;
      padding-right: 1rem;
      display: grid;
      grid-template-columns: auto 22px;
      align-items: center;
      i {
        display: flex !important;
      }
    }
  }
  @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.sm}) {
    grid-template-columns: 1fr 1fr;
  }
`;

const FancyModalButton = ({
  children,
  isOpen,
  toggleModal,
  header,
  footer,
  footerOptions,
  modalWidth,
  modalHeight,
  gtc,
  ...rest
}) => (
  <StyledModal
    isOpen={isOpen}
    onBackgroundClick={toggleModal}
    onEscapeKeydown={toggleModal}
    {...rest}
  >
    <ModalContainer modalWidth={modalWidth} modalHeight={modalHeight}>
      <span className="close-btn">
        <Tippy content="Close">
          <button type="button" onClick={toggleModal}>
            <Icon icon={ic_close} size={16} />
          </button>
        </Tippy>
      </span>
      <ModalHeader className="modal-header">
        <h1>{header}</h1>
      </ModalHeader>
      <ModalBody className="modal-header">{children}</ModalBody>
      <ModalFooter footerOptions={footerOptions} gtc={gtc}>
        {footer || (
          <>
            <button type="button" className="cancel-btn" onClick={toggleModal}>
              Close
            </button>
            <div className="continue-btn">
              <button type="button">
                Continue <Icon size={24} icon={ic_chevron_right} />
              </button>
            </div>
          </>
        )}
      </ModalFooter>
    </ModalContainer>
  </StyledModal>
);

export { ModalProvider };
export default FancyModalButton;
