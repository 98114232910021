export default {
  en: {
    dashboard: 'Dashboard',
    templates: 'Templates',
    new_poll: 'New Poll',
    new: 'New',
    upgrade: 'Upgrade',
    cancel: 'Cancel',
    send_feedback: 'Send feedback',
    specific_issue: 'Have a specific issue? Contact us for',
    support: 'support',
    check_our: 'or check our',
    guides: 'guides',
    feedback: 'Feedback',
    help_us_improve: 'Help us improve this page.'
  },
  es: {
    dashboard: 'Dashboard',
    templates: 'Templates',
    new_poll: 'Nuevo Poll',
    new: 'Nuevo',
    upgrade: 'Upgrade',
    cancel: 'Cancelar',
    send_feedback: 'Enviar feedback',
    specific_issue: 'Algún problema en específico? Contacta nuestro',
    support: 'soporte',
    check_our: 'o revisa nuestras',
    guides: 'guías',
    feedback: 'Feedback',
    help_us_improve: 'Ayúdanos a mejorar esta página.'
  }
};
