export default {
  en: {
    new_folder: 'Add a new folder',
    add_folder: 'Add folder',
    all: 'All',
    uncategorized: 'Uncategorized',
    create_new_poll: 'Create new Poll',
    pinned_polls: 'Pinned Polls',
    status: 'Status',
    responses: 'Responses',
    details: 'Details',
    showing: 'Showing',
    polls: 'Polls',
    last_modified: 'Last modified',
    questions: 'Questions',
    last_response: 'Last Response',
    settings: 'Settings',
    pinned: 'Pinned',
    pin: 'Pin',
    add_your: 'Add your',
    to_custom_folder: 'to a custom folder',
    cancel: 'Cancel',
    update: 'Update',
    change_folder: 'Change folder',
    add_to_folder: 'Add to folder',
    are_you_sure: 'Are you sure',
    remove: 'Remove',
    delete: 'Delete',
    deleting_your: 'Deleting your',
    will_destroy: 'will destroy all of your submission data',
    cannot_undone: 'This action cannot be undone',
    loading: 'Loading',
    no_polls: 'No Polls at the moment',
    get_started: 'Get started and Create a new Poll',
    edit_folder: 'Edit folder',
    update_folder: 'Update folder',
    remove_folder: 'Remove folder',
    are_your_sure: 'Are you sure you want to remove',
    folder: 'folder',
    never: 'Never'
  },
  es: {
    new_folder: 'Crear nueva carpeta',
    add_folder: 'Nueva carpeta',
    all: 'Todos',
    uncategorized: 'Sin categorizar',
    create_new_poll: 'Crear nuevo Poll',
    pinned_polls: 'Polls fijados',
    status: 'Estatus',
    responses: 'Respuestas',
    details: 'Detalles',
    showing: 'Mostrando',
    polls: 'Polls',
    last_modified: 'Última modificación',
    questions: 'Preguntas',
    last_response: 'Última Respuesta',
    settings: 'Ajustes',
    pinned: 'Fijado',
    pin: 'Fijar',
    add_your: 'Agrega tu',
    to_custom_folder: 'a una carpeta',
    cancel: 'Cancelar',
    update: 'Actualizar',
    change_folder: 'Cambiar carpeta',
    add_to_folder: 'Agregar a carpeta',
    are_you_sure: 'Estás seguro',
    remove: 'Remover',
    delete: 'Eliminar',
    deleting_your: 'Eliminando tu',
    will_destroy: 'destruirá todos los datos registrados.',
    cannot_undone: 'Esta acción no se puede deshacer',
    loading: 'Cargando',
    no_polls: 'Sin Polls por el momento',
    get_started: 'Comienza y crea un nuevo Poll',
    edit_folder: 'Editar carpeta',
    update_folder: 'Actualizar carpeta',
    remove_folder: 'Eliminar carpeta',
    are_your_sure: 'Estás seguro que quieres eliminar',
    folder: 'carpeta',
    never: 'Nunca'
  }
};
