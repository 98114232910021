/* eslint-disable camelcase */
import dashboard from './dashboard';

const indexPage = {
  en: { heading: "Polls and Forms that don't suck" },
  es: { heading: 'Encuestas y formularios que no apestan' }
};

export default {
  en: { index: indexPage.en, dashboard: dashboard.en },
  es: { index: indexPage.es, dashboard: dashboard.es }
};
