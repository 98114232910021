import * as Sentry from '@sentry/node';
import { RewriteFrames } from '@sentry/integrations';

// let Handlers;
// if (Sentry.Handlers) {
//   Handlers = Sentry.Handlers;
// }
// console.log({ Handlers });

export const init = () => {
  if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
    const integrations = [];
    if (process.env.NEXT_IS_SERVER === 'true' && process.env.NEXT_PUBLIC_SENTRY_SERVER_ROOT_DIR) {
      // For Node.js, rewrite Error.stack to use relative paths, so that source
      // maps starting with ~/_next map to files in Error.stack with path
      // app:///_next
      integrations.push(
        new RewriteFrames({
          iteratee: frame => {
            frame.filename = frame.filename.replace(
              process.env.NEXT_PUBLIC_SENTRY_SERVER_ROOT_DIR,
              'app:///'
            );
            frame.filename = frame.filename.replace('.next', '_next');
            return frame;
          }
        })
      );
    }

    Sentry.init({
      enabled: process.env.NODE_ENV === 'production',
      integrations,
      dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
      release: process.env.NEXT_PUBLIC_COMMIT_SHA
    });
  }
};

// export const withSentry = handler => async (req, res) => {
//   console.log('callback');
//   // console.log(handler);
//   init();
//   try {
//     console.log('calling handler');
//     // TODO: Start Transaction
//     return await handler(req, res); // Call Handler
//     // TODO: Finish Transaction
//   } catch (error) {
//     Sentry.captureException(error);
//   }
//   // Flushing before returning is necessary if deploying to Vercel, see
//   // https://vercel.com/docs/platform/limits#streaming-responses
//   await Sentry.flush(2000);
// };

export const sendErrorToSentry = async err => {
  init();
  Sentry.captureException(err);
  await Sentry.flush(2000);
};

export const withSentry = handler => async (req, res) => {
  init();
  try {
    // TODO: Start Transaction
    return await handler(req, res); // Call Handler
    // TODO: Finish Transaction
  } catch (e) {
    Sentry.captureException(e);
    // Sentry.withScope(scope => {
    //   scope.addEventProcessor(event => Sentry.Handlers.parseRequest(event, req));
    //   Sentry.captureException(e);
    // });
    await Sentry.flush(2000);
    throw e;
  }
};
