import layout from './layout';
import pages from './pages';

const generateIds = locale => {
  const library = [
    {
      pages: pages[locale]
    },
    {
      layout: layout[locale]
    }
  ];

  const idMapping = library.reduce((results, currentField) => {
    const [currField] = Object.entries(currentField);
    const [context, object] = currField;
    let currResults = {};
    for (const [id, msg] of Object.entries(object)) {
      const keys = Object.keys(msg);
      const res = keys.reduce((acc, curr) => {
        const field = `${context}.${id}.${curr}`;
        return { ...acc, [field]: msg[curr] };
      }, {});
      currResults = { ...currResults, ...res };
    }
    return { ...results, ...currResults };
  }, {});

  return idMapping;
};

export default {
  en: { ...generateIds('en') },
  es: { ...generateIds('es') }
};
