/* eslint-disable camelcase */
import navbar from './navbar';
import navbar_account_avatar from './navbar_account_avatar';
import navbar_user_navbar from './navbar_user_navbar';
import footer from './footer';

export default {
  en: {
    navbar: navbar.en,
    navbar_account_avatar: navbar_account_avatar.en,
    navbar_user_navbar: navbar_user_navbar.en,
    footer: footer.en
  },
  es: {
    navbar: navbar.es,
    navbar_account_avatar: navbar_account_avatar.es,
    navbar_user_navbar: navbar_user_navbar.es,
    footer: footer.es
  }
};
