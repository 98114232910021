export default {
  en: {
    features: 'Features',
    premium: 'Premium',
    templates: 'Templates',
    contact: 'Contact',
    new: 'New',
    new_poll: 'New Poll',
    log_in: 'Log In',
    sign_up: 'Sign Up'
  },
  es: {
    features: 'Producto',
    premium: 'Premium',
    templates: 'Templates',
    contact: 'Contacto',
    new: 'Nuevo',
    new_poll: 'Nuevo Poll',
    log_in: 'Log In',
    sign_up: 'Sign Up'
  }
};
