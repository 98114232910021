// function MyApp({ Component, pageProps }) {
//   return <Component {...pageProps} />
// }

// export default MyApp
import Head from 'next/head';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { tint, darken, lighten } from 'polished';
import * as gtag from '@/lib/gtag';
import NextNprogress from 'nextjs-progressbar';
// https://github.com/Mastermindzh/react-cookie-consent
import CookieConsent, { Cookies } from 'react-cookie-consent';
import { StyledCookieBar } from '@/styles/index';
// import TopBar from '@/components/TopBar';
import { ModalProvider } from '@/components/Modal';
// i18n
import { IntlProvider } from 'react-intl';
import intlMessages from '@/i18n/index';
// amplify config
import '@/lib/configureAmplify';
import { init } from '@/utils/sentry';
import { Provider as SessionProvider } from 'next-auth/client';
import '@/components/NXProgress/nxprogress.css';
// https://next-auth.js.org/getting-started/client#provider

const colors = {
  blue: '#2372E8',
  indigo: '#6610f2',
  purple: '#6f42c1',
  pink: '#e83e8c',
  pinkRed: '#fa3a69',
  green: '#28a745',
  teal: '#20c997',
  cyan: '#17a2b8',
  red: '#dc3545',
  orange: '#fd7e14',
  lightYellow: '#feca2f',
  yellow: '#ffc107',
  grey: '#6c757d',
  darkGrey: '#343a40',
  muted: '#eff2f6',
  mutedStrong: '#9095aa'
};

const breakpoints = {
  xs: '335px',
  sm: '448px',
  md: '574px',
  lg: '767px',
  xl: '991px',
  xxl: '1200px',
  xxxl: '1400px'
};

const theme = {
  colors,
  breakpoints
};

// style inspo:
// formium.io
// https://tripetto.com/no-code-calculations-with-the-calculator-block/
// doddle
// meta.inc

const GlobalStyle = createGlobalStyle`

  ${
    '' /* @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,700;1,300;1,400&family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400&display=swap'); */
  }

  :root {
    --background: #fcfcfd;
    --background-alt: #FAF7F5;
    --white: #ffffff;
    --white-hover: #F8F9FA;
    --black: #1a1a1a;
    ${'' /* --muted: #ced4da; */}
    --muted: ${colors.muted};
    ${'' /* --muted-strong: #6c757d; */}
    --muted-strong: ${colors.mutedStrong};
    --blue: ${colors.blue};
    --blue-hover: ${tint(0.2, colors.blue)};
    --blue-background:${tint(0.7, colors.blue)};
    --blue-background-light: ${tint(0.8, colors.blue)};
    --blue-background-extra-light: ${tint(0.95, colors.blue)};
    --indigo: ${colors.indigo};
    --indigo-hover: ${tint(0.2, colors.indigo)};
    --purple: ${colors.purple};
    --purple-hover: ${tint(0.2, colors.purple)};
    --pink: ${colors.pink};
    --pink-hover: ${tint(0.2, colors.pink)};
    --pink-red: ${colors.pinkRed};
    --pink-red-hover: ${tint(0.2, colors.pinkRed)};
    --green: ${colors.green};
    --green-hover: ${tint(0.2, colors.green)};
    --teal: ${colors.teal};
    --cyan: ${colors.cyan};
    --yellow: ${colors.yellow};
    --yellow-hover: ${tint(0.2, colors.yellow)};
    --orange: ${colors.orange};
    --orange-hover: ${tint(0.2, colors.orange)};
    --red: ${colors.red};
    --red-hover: ${tint(0.2, colors.red)};
    --grey: ${colors.grey};
    --dark-grey: ${colors.darkGrey};
    --danger: ${colors.red};
    --danger-hover: ${tint(0.2, colors.red)};
    --danger-background: #f8d7da;
    --warning: ${darken(0.2, '#fff3cd')};
    --warning-background: ${lighten(0.02, '#fff3cd')};
    --info: ${colors.cyan};
    --info-hover: ${lighten(0.02, colors.cyan)};
    ${'' /* --text-color: #45505E; */}
    --text-color: #18214d;
    --text-color-hover: #6A7685;
    --border-color: var(--muted);
    --border-radius: 4px;
    --border: 1px solid ${darken(0.1, colors.muted)};
    --item-height: 48px;
    --btn-box-shadow-active: inset 0 0 3px 0 rgba(0,0,0,0.5);
    --btn-disabled-color: #e4e5e9;
    --btn-background-color: #eff1f5;
    --btn-background-color-hover: #e1e3ea;
    --btn-text-color: var(--text-color);
    --font-base: 'Open Sans';
    --font-heading: 'Poppins';
    --desktop-padding-sizes: 3.5rem;
    --mobile-padding-sizes: 1.5rem;
    --breakpoint-xs: ${breakpoints.xs};
    --breakpoint-sm: ${breakpoints.sm};
    --breakpoint-md: ${breakpoints.md};
    --breakpoint-lg: ${breakpoints.lg};
    --breakpoint-xl: ${breakpoints.xl};
    --breakpoint-xxl: ${breakpoints.xxl};
    --opacity-base: 0.64;
  }
  html {
    font-size: 14px;
  }
  html,
  body {
    padding: 0;
    margin: 0;
    color: var(--text-color);
    box-sizing: border-box;
    font-family: var(--font-base), -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
      Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    font-weight: 400;
    font-size: 14px;
  }
  input, textarea {
    font-family: var(--font-base), -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
      Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  }

  textarea {
    resize: none;
  }

  a {
    color: inherit;
    text-decoration: none;
    font-family: var(--font-heading), -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
      Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    font-weight: 500;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: var(--font-heading), -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
      Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    font-weight: 500;
  }

  button {
    cursor: pointer;
    font-family: var(--font-heading), -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
      Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    font-weight: 500;
    /** base pollme buttons */
    border: none;
    border-radius: var(--border-radius);
    ${'' /* padding: 0.65rem 0.875rem; */}
    color: var(--btn-text-color);
    background-color: var(--btn-background-color);
    &:hover {
      background-color: var(--btn-background-color-hover);
    }
  }
  button:disabled {
    cursor: auto;
  }

  * {
    box-sizing: border-box;
  }

  .btn-primary {
    color: var(--white);
    background-color: var(--blue);
    &:hover {
      background-color: var(--blue-hover);
    }
  }
  .btn-danger {
    color: var(--white);
    background-color: var(--red);
    &:hover {
      background-color: var(--red-hover);
    }
  }
  .divider {
    height: 2px;
    background-color: var(--muted);
    width: 100%;
    margin: 1rem 0;
    &.left {
      margin-right: auto;
    }
    &.right {
      margin-left: auto;
    }
    &.center {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .ads-left {
    position: absolute;
    float: left;
    width: 20%;
    height: 100%;
    left: 0;
    &.top {
      top: 10vh;
    }
    &.top.with-heading{
      top: 100px;
    }
    &.bottom {
      bottom: 5vh;
    }
  }
  .ads-right {
    position: absolute;
    float: right;
    text-align: right;
    width: 20%;
    height: 100%;
    right: 0;
    &.top {
      top: 10vh;
    }
    &.top.with-heading{
      top: 100px;
    }
    &.bottom {
      bottom: 5vh;
    }
  }
  .ads-block {
    &.centered {
      text-align: center;
      width: 100%;
      min-height: 90px;
      margin: auto;
    }
  }
  .gradient-text {
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
  }
  .gradient-primary {
    color: var(--blue);
    background-color: var(--blue);
    background-image: linear-gradient(45deg, var(--blue), var(--indigo));
  }
  .gradient-pink {
    color: var(--pink);
    background-color: var(--pink);
    background-image: linear-gradient(45deg, var(--pink), var(--orange));
  }
`;

// sentry init
init();

export default function App({ Component, pageProps }) {
  const router = useRouter();
  const { locale = 'en', defaultLocale } = router;
  const [isOnIframe, setIsOnIframe] = useState(false);

  // check if on iframe to display cookie banner
  useEffect(() => {
    function inIframe() {
      try {
        // access to window.top will cause cross-origin error on external sites, triggering the catch, meaning is on iframe
        return window.self !== window.top;
      } catch (e) {
        return true;
      }
    }
    if (router) {
      const isInIframe = inIframe();
      if (isInIframe) {
        setIsOnIframe(true);
      }
    }
  }, [router]);

  useEffect(() => {
    if (!isOnIframe) {
      const handleRouteChange = url => {
        gtag.pageview(url);
      };
      router.events.on('routeChangeComplete', handleRouteChange);
      return () => {
        router.events.off('routeChangeComplete', handleRouteChange);
      };
    }
  }, [router.events, isOnIframe]);

  // const handleDeclineCookie = () => {
  //   console.log('disable tracking');
  //   // // remove google analytics cookies
  //   Cookies.remove('_ga');
  //   Cookies.remove('_gat');
  //   Cookies.remove('_gid');
  //   gtag.disableGA();
  //   gtag.disableAdsTracking();
  // };

  const messages = intlMessages[locale];
  // Component.omitProgress and omitSession for embed pages
  return (
    <IntlProvider messages={messages} locale={locale} defaultLocale={defaultLocale}>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <ModalProvider>
          {!Component.omitProgress && (
            <NextNprogress color="var(--blue)" startPosition={0.3} stopDelayMs={50} height="3" />
          )}
          {Component.omitSession ? (
            <Component {...pageProps} />
          ) : (
            <SessionProvider session={pageProps.session}>
              {/* <TopBar>
              <div>
                Currently on <strong>beta version</strong>, don't expect your data to be maintained.
                On active development.
              </div>
            </TopBar> */}
              <Component {...pageProps} />
            </SessionProvider>
          )}
        </ModalProvider>

        {!isOnIframe && (
          <StyledCookieBar>
            <CookieConsent
              // debug // IMPORTANT disable on PROD
              // enableDeclineButton
              // onDecline={handleDeclineCookie}
              buttonText="I understand"
              declineButtonText="I decline"
              disableStyles
              containerClasses="cookie-alert-bar"
              contentClasses="bar-content"
              buttonWrapperClasses="bar-buttons"
              buttonClasses="btn-primary"
              declineButtonClasses="btn-reject"
              overlayClasses="overlay-wrapper"
            >
              <strong>Pollme</strong> uses cookies to improve your experience on our site. Cookies
              enable you to use our services and are an essential part tool for user authentication
              and security, enable you to use our free services, and display ads to your interest.
              By continuing to use our site you consent to use our cookies.
            </CookieConsent>
          </StyledCookieBar>
        )}
      </ThemeProvider>
    </IntlProvider>
  );
}
