export default {
  en: {
    new_poll: 'New Poll',
    dashboard: 'Dashboard',
    account: 'Account',
    log_out: 'Log Out',
    log_in: 'Log In'
  },
  es: {
    new_poll: 'Nuevo Poll',
    dashboard: 'Dashboard',
    account: 'Cuenta',
    log_out: 'Salir',
    log_in: 'Log In'
  }
};
