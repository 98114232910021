import Amplify from 'aws-amplify';
import config from '../aws-exports';

// check config for faster resolvers on AppSync
// https://youtu.be/_9DFFg-pNss?t=865

const isLocalhost = Boolean(
  typeof window !== 'undefined' &&
    (window.location.hostname === 'localhost' ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === '[::1]' ||
      // 127.0.0.1/8 is considered localhost for IPv4.
      window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/))
);

// Assuming you have two redirect URIs, and the first is for localhost and second is for production
const [localRedirectSignIn, productionRedirectSignIn] = config.oauth.redirectSignIn.split(',');

const [localRedirectSignOut, productionRedirectSignOut] = config.oauth.redirectSignOut.split(',');

const updatedAwsConfig = {
  ...config,
  oauth: {
    ...config.oauth,
    redirectSignIn: isLocalhost ? localRedirectSignIn : productionRedirectSignIn,
    redirectSignOut: isLocalhost ? localRedirectSignOut : productionRedirectSignOut
  }
};

// Hosted UI Endpoint:
// https://pollmeapp6c81d937-6c81d937-dev.auth.us-west-2.amazoncognito.com/login?response_type=code&client_id=2ddd9qk51524s7d1nk1juseb5k&redirect_uri=http://localhost:3000/,https://pollme.app/

Amplify.configure(updatedAwsConfig);

// https://github.com/dabit3/next.js-amplify-workshop
// https://docs.amplify.aws/start/getting-started/data-model/q/integration/next
